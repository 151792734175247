//import React, { useEffect } from "react"
import React from "react"
import Header from "./header"
import { makeStyles } from "@material-ui/core/styles"
import { ThemeProvider, createTheme } from "@material-ui/core/styles"
import Footer from "./footer"
import { GatsbySeo, ArticleJsonLd } from "gatsby-plugin-next-seo"
import "./layout.css"
import CookieConsent from "react-cookie-consent"
//import { navigate } from "gatsby"
import Logo from "../icons/logo.png"
import Image from "../images/defaultImage.png"

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    minHeight: "100vh",
    minWidth: "100%",
    overflowX: "hidden", // prevent horizontal scroll
  },
  main: {
    fontFamily: "Montserrat",
    fontDisplay: "swap",
  },
}))

const theme = createTheme({
  palette: {
    primary: {
      light: "#83B9FC",
      main: "#2484F9",
      dark: "#055AC2",
      text: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffb95d",
      main: "#fe5f55",
      dark: "#c65900",
      contrastText: "#fff",
    },
    footer: {
      light: "#ffb95d",
      main: "#fe5f55",
      dark: "#c65900",
      contrastText: "#000",
    },
  },
})

export default function Layout({
  title,
  description,
  image,
  isArticle,
  url,
  date,
  children,
}) {
  const classes = useStyles()

  if (image === null || image === undefined) {
    image = Image
  }
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        {isArticle ? (
          <ArticleJsonLd
            url={url}
            headline={title}
            image={image}
            datePublished={date}
            dateModified={date}
            authorName="Giambattista Ferrari"
            publisherName="Jesolo San Donà Basket"
            publisherLogo={Logo}
            description={description}
            overrides={{
              "@type": "BlogPosting",
            }}
            openGraph={{
              url: "https://www.jesolosandonabasket.it",
              title: { title },
              description: { description },
              images: [
                {
                  url: { image },
                  width: 800,
                  height: 600,
                  alt: "Jesolo San Dona' Basket",
                },
              ],
              site_name: "Jesolo San Dona' Basket",
            }}
          />
        ) : (
          <GatsbySeo
            title={title}
            description={description}
            image={image}
            openGraph={{
              url: "https://www.jesolosandonabasket.it",
              title: "Jesolo San Dona Basket",
              description: { description },
              images: [
                {
                  url: { Logo },
                  width: 800,
                  height: 600,
                  alt: "Jesolo San Dona' Basket",
                },
              ],
              site_name: "Jesolo San Dona' Basket",
            }}
          />
        )}
        <Header />
        <main className={classes.main}>{children}</main>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Chiudi"
          declineButtonText="Rifiuta"
          style={{
            background: "#fff",
            color: "#000",
            fontFamily: "Montserrat",
            fontDisplay: "swap",
          }}
          buttonStyle={{
            background: "#2484F9",
            color: "#fff",
            fontFamily: "Montserrat",
            fontDisplay: "swap",
            fontSize: "20px",
          }}
          expires={150}
          acceptOnScroll={true}
          secure={true}
          sameSite="none"
          cookieName="gatsby-gdpr-google-analytics"
        >
          Questo sito usa i cookies per migliorare l'esperienza utente.{" "}
        </CookieConsent>
      </div>
    </ThemeProvider>
  )
}
