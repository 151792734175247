const ROUTES = [
  { name: "Home", to: "/" },
  { name: "Libro", to: "/libro/" },
  { name: "Articoli", to: "/articoli/" },
  //{ name: "Storia", to: "/storia/" },
  { name: "Squadre", to: "/squadre/" },
  { name: "Galleria", to: "/galleria/" },
  { name: "Manifesti", to: "/manifesti/" },
  { name: "Chi Siamo", to: "/chi-siamo/" },
  { name: "Contatti", to: "/contatti/" },
  { name: "Sponsor", to: "/sponsor/" },
]

export default ROUTES
