import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Box from "@material-ui/core/Box"
import Link from "./link"
import ROUTES from "../constants/routes"

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "absolute",
    width: "100%",
    maxWidth: "100%",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "auto",
    maxHeight: "50px",
    backgroundColor: "transparent",
    backgroundImage:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.69) 2%, hsla(0, 0.00%, 0.00%, 0.20) 80%, hsla(0, 0.00%, 100.00%, 0.00))",

    backgroundSize: "auto",
    backgroundRepeat: "repeat",
    backgroundAttachment: "scroll",
    backgroundPosition: "0px 0px",
  },
  link: {
    padding: "13px",
    color: "#fff",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontWeight: 700,
    activeStyle: {
      color: "#000",
    },
  },
}))

export default function HeaderDesktop(props) {
  const classes = useStyles()

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar variant="dense">
        <Box display="flex" flexGrow={1}>
          <Link
            to="/"
            activeStyle={{
              color: "#fff",
              fontFamily: "Montserrat",
              fontDisplay: "swap",
              fontSize: 20,
              fontWeight: 700,
            }}
            partiallyActive={true}
          >
            JSDB
          </Link>
        </Box>
        {ROUTES.map((obj, index) => (
          <Box key={index}>
            <Link
              to={obj.to}
              className={classes.link}
              activeStyle={{
                color: "#0082f3",
                fontWeight: 900,
              }}
            >
              {obj.name}
            </Link>
          </Box>
        ))}
      </Toolbar>
    </AppBar>
  )
}
