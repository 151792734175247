import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Link from "./link"
import emailjs from "emailjs-com"
import ROUTES from "../constants/routes"

const useStyles = theme => ({
  root: {
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    overflow: "hidden",
    marginLeft: 5,
    borderRadius: 10,
    "&:hover": {},
  },
  footer: {
    padding: theme.spacing(6, 0),
    backgroundColor: "#0c2483",
    marginTop: 20,
  },
  footerTitle: {
    color: "#fff",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "Montserrat",
    fontDisplay: "swap",
  },
  emailOptIn: {
    backgroundColor: "#4368ef",
    paddingTop: 50,
    paddingBottom: 50,
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    color: "#fff",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "50%",
    marginTop: theme.spacing(3),
  },
  margin: {
    backgroundColor: "white",
    borderRadius: 10,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: 20,
    width: "auto",
  },
  link: {
    padding: "13px",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: 20,
    fontWeight: 700,
    color: "#f2545b",
  },
})

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      mail: "",
      open: false,
    }
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleMailChange = this.handleMailChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal() {
    this.setState(state => {
      return {
        open: !state.open,
        name: "",
        mail: "",
      }
    })
    //setTimeout(function () {
    //  window.location.reload(false)
    //}, 5000)
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value })
  }
  handleMailChange(e) {
    this.setState({ mail: e.target.value })
  }
  handleSubmit(e) {
    e.preventDefault()

    if (this.state.mail !== "" && this.state.name !== "") {
      emailjs
        .send(
          "default_service",
          process.env.GATSBY_EMAILJS_TEMPLATE_NEWSUB,
          {
            from_name: this.state.name,
            reply_to: this.state.mail,
          },
          process.env.GATSBY_EMAILJS_USERID
        )
        .then(this.toggleModal())
        .catch(err =>
          console.error(
            "Oh well, you failed. Here some thoughts on the error that occured:",
            err.text
          )
        )
    }
  }

  render() {
    const { classes } = this.props

    return (
      <footer style={{ marginTop: "50px" }}>
        <section className={classes.footer}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <h2 className={classes.footerTitle}>
                    Collegamenti principali
                  </h2>
                </Grid>
                {ROUTES.map((obj, index) => (
                  <Grid item xs={12} key={index}>
                    <Link to={obj.to} className={classes.link}>
                      {obj.name}
                    </Link>
                  </Grid>
                ))}
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <a
                    className={classes.link}
                    href="https://www.facebook.com/JSDB-102432098235141"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    Facebook
                  </a>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 40 }}>
                  <p style={{ color: "#fff" }}>
                    © Copyright {new Date().getFullYear()}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </section>
      </footer>
    )
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(useStyles)(Footer)
