import React from "react"
import clsx from "clsx"
import AppBar from "@material-ui/core/AppBar"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import Toolbar from "@material-ui/core/Toolbar"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import Box from "@material-ui/core/Box"
import Link from "./link"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import ROUTES from "../constants/routes"

const drawerWidth = "75%"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    marginBottom: "20px",
  },
  appBar: {
    position: "absolute",
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "auto",
    maxHeight: "50px",
    backgroundColor: "transparent",
    color: "hsla(0, 0.00%, 0.00%, 1.00)",
    backgroundImage:
      "linear-gradient(to bottom, rgba(0, 0, 0, 0.69) 2%, hsla(0, 0.00%, 0.00%, 0.30) 50%, hsla(0, 0.00%, 100.00%, 0.00))",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: "25%",
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#fff",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    height: "auto",
    flexShrink: 1,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#305aed",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  linkContainer: {
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontWeight: 700,
    fontSize: 24,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  boxLink: {
    flexGrow: 1,
    marginBottom: 40,
  },
  link: {
    color: "#fff",
    borderBottom: "1px solid #fff",
    "&:focus, &:hover": {
      borderBottom: "1px solid #f2545b",
      color: "#f2545b",
      transition: theme.transitions.create(
        ["color", "color", "border", "color"],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }
      ),
    },
  },
}))

export default function HeaderMobile(props) {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const handleDrawer = () => {
    setOpen(prev => !prev)
  }
  const handleClickAway = () => {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <AppBar
          position="sticky"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="apri menu"
              onClick={handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Link
              to="/"
              activeStyle={{
                color: "#fff",
                fontFamily: "Montserrat",
                fontDisplay: "swap",
                fontSize: 20,
                fontWeight: 700,
              }}
              partiallyActive={true}
            >
              JSDB
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{ paper: classes.drawerPaper }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawer}>
              <CloseIcon style={{ color: "#fff" }} />
            </IconButton>
          </div>
          <div className={classes.linkContainer}>
            {ROUTES.map((obj, index) => (
              <Box className={classes.boxLink} key={index}>
                <Link
                  to={obj.to}
                  className={classes.link}
                  activeStyle={{
                    backgroundColor: "#fff",
                    color: "#0082f3",
                    fontWeight: 900,
                  }}
                >
                  {obj.name}
                </Link>
              </Box>
            ))}
          </div>
        </Drawer>
      </div>
    </ClickAwayListener>
  )
}
