import React from "react"
import HeaderMobile from "./headerMobile"
import HeaderDesktop from "./headerDesktop"
import Hidden from "@material-ui/core/Hidden"

export default function Header() {
  return (
    <>
      <Hidden smDown>
        <HeaderDesktop />
      </Hidden>
      <Hidden mdUp>
        <HeaderMobile />
      </Hidden>
    </>
  )
}
